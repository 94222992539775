"use client";
import AuthForm from "@/components/auth/auth_form";
import AuthTopNav from "@/components/auth/AuthTopNav";
import { Button } from "@/components/ui/button";
import { AuthSchema } from "@/lib/validators/auth_validator";
import { Spinner } from "@radix-ui/themes/dist/cjs/components/spinner";
import { createClientComponentClient } from "@supabase/auth-helpers-nextjs";
import { Google } from "iconsax-react";
import { NextPage } from "next";
import { useRouter } from "next/navigation";
import { useState } from "react";
import { toast } from "sonner";

interface Props {}

const SignIn: NextPage<Props> = () => {
  const supabase = createClientComponentClient();
  const [loading, setLoading] = useState<boolean>(false);
  const router = useRouter();

  const login = async (creds: AuthSchema) => {
    setLoading(true);
    const { data, error } = await supabase.auth.signInWithPassword({
      email: creds.email,
      password: creds.password,
    });
    if (error) {
      toast.error(error.message);
      setLoading(false);
    }
    if (data && data.user) {
      router.push("/dashboard");
    }
  };

  const signinWithGoogle = async () => {
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: "google",
      options: {
        // redirectTo: "http://localhost:3000/api/auth/callback",
        redirectTo: "https://app.amezwallet.com/api/auth/callback",
        // queryParams: {
        //   access_type: "offline",
        //   prompt: "consent",
        // },
      },
    });

    if (error) {
      toast.error(error.message);
    }
  };
  return (
    <div className="container">
      <AuthTopNav />
      <div className="py-12">
        <div className="m-auto w-[100%] md:w-[50%]  ">
          {/* <h1
            className={`text-2xl font-bold text-center  text-transparent bg-clip-text bg-gradient-to-bl from-zinc-400 to-zinc-800`}
          >
            Welcome back 
            <br /> Ready for more of the best
          </h1> */}

          <div className="border border-zinc-100 dark:border-neutral-800 rounded-3xl py-10 px-8 w-[100%] md:w-[70%] mt-8 m-auto ">
            <h5 className="text-xl font-bold text-zinc-400 dark:text-neutral-50">
              Welcome back
            </h5>
            <p className="text-sm text-zinc-600 dark:text-neutral-400">
              Continue from where you left
            </p>
            <Button
              variant="outline"
              size="lg"
              className="space-x-4  mt-4 w-full"
              onClick={signinWithGoogle}
            >
              <Google
                className="text-zinc-500 dark:text-zinc-300"
                size="20"
                variant="Bold"
              />
              <p>Sign In With Google</p>
            </Button>
            <Spinner loading />
            <p className="text-center text-xs mt-4 text-zinc-500 ">
              or with email
            </p>
            <AuthForm onFormSubmit={login} loading={loading} type="signin" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
